<template src="./MassManagementForm.html"></template>
<style scoped lang="scss" src="./MassManagementForm.scss"></style>

<script>
import moment from "moment";
import { ServiceFactory } from "@/services";
import { TypeMasterData } from "@/constants";
import { numberComma } from "@/utils/number.util";
const MasterManagementService = ServiceFactory.get('master')

export default {
  name: 'MassManagementForm',
  props: {
    order: {
      require: false,
      type: Object,
      default: () => ({})
    },
    isEdit: {
      require: false,
      type: Boolean,
      default: false
    },
    isLoading: {
      require: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    const validateSpecDate = (rule, value, callback) => {
      const { specDate, deliveryDate } = this.formData

      if (!deliveryDate) return callback()
      if (!moment(specDate).isBefore(deliveryDate)) return callback('仕様書送信日は納品日より前である必要があります。')
      return callback()
    }

    const validateOrderDate = (rule, value, callback) => {
      const { orderDate, deliveryDate } = this.formData

      if (!deliveryDate) return callback()
      if (!moment(orderDate).isBefore(deliveryDate)) return callback('発注日は納品日より前である必要があります。')
      return callback()
    }

    return {
      categoryOptions: [],
      bodyOptions: [],
      brandOptions: [],
      orderFactoryOptions: [],
      importerOptions: [],
      orderProblemOptions:[],
      classOptions: [],
      sampleIDOptions: [],
      sizeOptions: [],
      colorOptions: [],
      nameOfOrderOptions: [],
      formData: {
        categoryId: '',
        bodyId: '',
        brandId: '',
        factoryId: '',
        importerId: '',
        contentId: '',
        classifyId: '',
        code: '',
        sizeId: '',
        colorId: '',
        quantity: '',
        unitPrice: '',
        fee: '',
        actualQuantity: '',
        formCode: '',
        paymentCode: '',
        orderDate : '',
        specDate: '',
        deliveryDate: '',
        file: '',
        note: '',
        internalNote: ''
      },
      rules: {
        categoryId: [
          { required: true, message: '必須項目です。', trigger: 'change' }
        ],
        bodyId: [
          { required: true, message: '必須項目です。', trigger: 'change' }
        ],
        brandId: [
          { required: true, message: '必須項目です。', trigger: 'change' }
        ],
        factoryId: [
          { required: true, message: '必須項目です。', trigger: 'change' }
        ],
        importerId: [
          { required: true, message: '必須項目です。', trigger: 'change' }
        ],
        classifyId: [
          { required: true, message: '必須項目です。', trigger: 'change' }
        ],
        code: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 255, message: '255文字以内を入力してください', trigger: 'blur' }
        ],
        sizeId: [
          { required: true, message: '必須項目です。', trigger: 'change' }
        ],
        colorId: [
          { required: true, message: '必須項目です。', trigger: 'change' }
        ],
        quantity: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'number', message: '正数を入力してください。', trigger: 'blur' },
          { type: 'number', min: 0, message: '正数を入力してください。', trigger: 'blur' },
          { type: 'number', max: 9999999999, message: '9999999999以内を入力してください', trigger: 'blur' }
        ],
        actualQuantity: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'number', message: '入荷数量は数値でなければなりません。', trigger: 'blur' },
          { type: 'number', min: 0, message: '正数を入力してください。', trigger: 'blur' }
        ],
        unitPrice: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'number', message: '単価は数値でなければなりません。', trigger: 'blur' },
          { type: 'number', min: 0, message: '正数を入力してください。', trigger: 'blur' },
          { type: 'number', max: 9999999999, message: '9999999999以内を入力してください', trigger: 'blur' }
        ],
        fee: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'number', message: '入荷数量は数値でなければなりません。', trigger: 'blur' },
          { type: 'number', min: 0, message: '正数を入力してください。', trigger: 'blur' },
          { type: 'number', max: 9999999999, message: '9999999999以内を入力してください', trigger: 'blur' }
        ],
        formCode: [
          // { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 255, message: '255文字以内を入力してください', trigger: 'blur' }
        ],
        contentId: [
          { required: true, message: '必須項目です。', trigger: 'change' },
        ],
        paymentCode: [
          // { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 255, message: '255文字以内を入力してください', trigger: 'blur' }
        ],
        deliveryDate: [
          // { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        specDate: [
          // { required: true, message: '必須項目です。', trigger: 'blur' },
          { validator: validateSpecDate, trigger: 'blur' }
        ],
        orderDate: [
          // { required: true, message: '必須項目です。', trigger: 'blur' },
          { validator: validateOrderDate, trigger: 'blur' }
        ],
        file: [
          // { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 255, message: '255文字以内を入力してください', trigger: 'blur' }
        ],
        note: [
          // { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 255, message: '255文字以内を入力してください', trigger: 'blur' }
        ],
        internalNote: [
          // { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 255, message: '255文字以内を入力してください', trigger: 'blur' }
        ]
      },
      post: ''
    };
  },
  watch: {
    order: function (oldData) {
      if (!oldData || !oldData.id) return

      this.formData.categoryId = oldData.categoryId || 0
      this.formData.bodyId = oldData.bodyId || 0
      this.formData.brandId = oldData.brandId || 0
      this.formData.factoryId = oldData.factoryId || 0
      this.formData.importerId = oldData.importerId || 0
      this.formData.contentId = oldData.contentId || 0
      this.formData.classifyId = oldData.classifyId || 0
      this.formData.code = oldData.code || ''
      this.formData.sizeId = oldData.sizeId || ''
      this.formData.colorId = oldData.colorId || ''
      this.formData.quantity = oldData.quantity || 0
      this.formData.unitPrice = oldData.unitPrice || 0
      this.formData.fee = oldData.fee || 0
      this.formData.actualQuantity = oldData.actualQuantity || 0
      this.formData.formCode = oldData.formCode || ''
      this.formData.paymentCode = oldData.paymentCode || ''
      this.formData.orderDate = oldData.orderDate || ''
      this.formData.specDate = oldData.specDate || ''
      this.formData.deliveryDate = oldData.deliveryDate || ''
      this.formData.file = oldData.file || ''
      this.formData.note = oldData.note || ''
      this.formData.internalNote = oldData.internalNote || ''
    }
  },
  computed: {
    submitText() {
      if (this.isEdit) return '保存'
      return '新規作成'
    },
    totalPrice() {
      const total = Number(this.formData.quantity) * Number(this.formData.unitPrice) + Number(this.formData.fee)

      if (isNaN(total) || total < 0) return 0
      return  numberComma(total.toFixed(0))
     }
  },
  created() {
    this.init();
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
    },

    async onSubmit() {
      const isValid = await this.$refs.form.validate()
      if (!isValid) return

      this.$emit('submit', this.formData)
    },

    async init() {
      await Promise.all([
          this.getCategoryOptions(),
          this.getBodyOptions(),
          this.getBrandOptions(),
          this.getOrderFactoryOptions(),
          this.getImporterOptions(),
          this.getOrderProblemOptions(),
          this.getClassifyOptions(),
          this.getSizeOptions(),
          this.getColorOptions()
      ])
    },

    async getCategoryOptions() {
      this.categoryOptions = await this.getOptionListItems(TypeMasterData.category)
    },

    async getBodyOptions() {
      this.bodyOptions = await this.getOptionListItems(TypeMasterData.body)
    },

    async getBrandOptions() {
      this.brandOptions = await this.getOptionListItems(TypeMasterData.brand)
    },

    async getOrderFactoryOptions() {
      this.orderFactoryOptions = await this.getOptionListItems(TypeMasterData.factory)
    },

    async getImporterOptions() {
      this.importerOptions = await this.getOptionListItems(TypeMasterData.importer)
    },

    async getOrderProblemOptions() {
      this.orderProblemOptions = await this.getOptionListItems(TypeMasterData.problemContent)
    },

    async getClassifyOptions() {
      this.classOptions = await this.getOptionListItems(TypeMasterData.classify)
    },

    async getColorOptions() {
      this.colorOptions = await this.getOptionListItems(TypeMasterData.color)
    },

    async getSizeOptions() {
      this.sizeOptions = await this.getOptionListItems(TypeMasterData.size)
    },

    async getOptionListItems(type) {
      const items = await MasterManagementService.getItemsList({ type }).catch(() => ([]))
      return items || []
    }
  }
}
</script>
